// theme config import
import themeConfig from '@configs/themeConfig';
// ** Custom Hooks
import { useSkin } from '@hooks/useSkin';
import lightLogo from '../../../assets/images/logo/black-logo-marun.svg';
const SpinnerComponent = () => {
    // ** Hooks
    const { skin } = useSkin();
    return (
        <div className="fallback-spinner app-loader">
            <img
                className="fallback-logo"
                src={skin === 'dark' ? themeConfig.app.appLogo : lightLogo}
                alt="logo"
                width="auto"
                height="60px"
            />
            <div className="loading">
                <div className="effect-1 effects"></div>
                <div className="effect-2 effects"></div>
                <div className="effect-3 effects"></div>
            </div>
        </div>
    );
};

export default SpinnerComponent;
