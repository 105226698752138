import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDevice = createAsyncThunk('deviceSlice/getDevice', async () => {
    try {
        let response = await axios.get('/device', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDeviceOption = createAsyncThunk('deviceSlice/getDeviceOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/device?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDevice = createAsyncThunk('deviceSlice/createDevice', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/device', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule created successfully.');
        dispatch(getDevice());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDevice = createAsyncThunk('deviceSlice/updateDevice', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/device/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule updated successfully.');
        dispatch(getDevice());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDevice = createAsyncThunk('deviceSlice/deleteDevice', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/device/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule deleted successfully');
        dispatch(getDevice());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDeviceStatus = createAsyncThunk('deviceSlice/updateDeviceStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/device/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getDevice());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deviceSlice = createSlice({
    name: 'deviceSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDevice.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getDeviceOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default deviceSlice.reducer;
