// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'easymba-admin-panel',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};

const initialProfileTab = () => {
    const item = window.localStorage.getItem('profileTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.profileTab;
};

const initialUserTab = () => {
    const item = window.localStorage.getItem('userTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.userTab;
};

const initialObdTab = () => {
    const item = window.localStorage.getItem('obdTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.obdTab;
};

export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab(),
        profileTab: initialProfileTab(),
        userTab: initialUserTab(),
        obdTab: initialObdTab()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },
        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '"1"');
        },
        handleProfileTab: (state, action) => {
            state.profileTab = action.payload;
            localStorage.setItem('profileTab', JSON.stringify(action.payload));
        },
        removeProfileTabs: (state, action) => {
            state.profileTab = '1';
            localStorage.setItem('profileTab', '"1"');
        },
        handleUserTab: (state, action) => {
            state.userTab = action.payload;
            localStorage.setItem('userTab', JSON.stringify(action.payload));
        },
        removeUserTabs: (state, action) => {
            state.userTab = '1';
            localStorage.setItem('userTab', '"1"');
        },
        handleObdTab: (state, action) => {
            state.obdTab = action.payload;
            localStorage.setItem('obdTab', JSON.stringify(action.payload));
        }
    }
});

export const { handleActiveTab, removeTabs, handleProfileTab, removeProfileTabs, handleUserTab, handleObdTab, removeUserTabs } =
    tabSlice.actions;

export default tabSlice.reducer;
