// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getActiveClosed = createAsyncThunk('getAllActiveClosedData/getActiveClosed', async () => {
    try {
        let response = await axios.get('/help-desk', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getHelpCategoryOption = createAsyncThunk('getAllActiveClosedData/getHelpCategoryOption', async () => {
    try {
        // showLoader(true);
        let response = await axios.get('/help-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getHelpDeskRemarks = createAsyncThunk('getAllActiveClosedData/getHelpDeskRemarks', async (id) => {
    try {
        let response = await axios.get('/help-desk/get-remarks/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateHelpRequestStatus = createAsyncThunk('getAllActiveClosedData/updateHelpRequestStatus', async (data) => {
    try {
        let response = await axios.put('/help-desk/request-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Helpdesk Ticket update succesfully.');
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createHelpDesk = createAsyncThunk('getAllActiveClosedData/createHelpDesk', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/help-desk', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'New Request added succesfully.');
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createForwardRequest = createAsyncThunk('getAllActiveClosedData/createForwardRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/help-desk/forward', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Forward Request added succesfully.');

        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateNewRequest = createAsyncThunk('getAllActiveClosedData/updateNewRequest', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/help-desk/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'New Request updated succesfully.');
        // await dispatch(getAllowance());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateActiveClosedStatus = createAsyncThunk('getAllActiveClosedData/updateActiveClosedStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/help-desk/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        // dispatch(getActiveClosed());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteActiveClosed = createAsyncThunk('getAllActiveClosedData/deleteActiveClosed', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/help-desk/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'ActiveClosed deleted successfully.');
        // await dispatch(getAllowance());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const helpDeskActiveClosedSlice = createSlice({
    name: 'getAllActiveClosedData',

    initialState: {
        activeClosed: [],
        option: []
    },
    reducers: {
        removeActiveClosed: (state) => {
            state.activeClosed = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveClosed.fulfilled, (state, action) => {
            state.activeClosed = action?.payload;
            return;
        });
        builder.addCase(getHelpCategoryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export const { removeActiveClosed } = helpDeskActiveClosedSlice.actions;

export default helpDeskActiveClosedSlice.reducer;
