// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getFaqCategory = createAsyncThunk('faqCategorySliceData/getFaqCategory', async () => {
    try {
        let response = await axios.get('/faq-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFaqCategoryOption = createAsyncThunk('faqCategorySliceData/getFaqCategoryOption', async () => {
    try {
        let response = await axios.get('/faq-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFaqCategoryStatus = createAsyncThunk('faqCategorySliceData/getFaqCategoryStatus', async () => {
    try {
        let response = await axios.get('/faq-category?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteFaqCategory = createAsyncThunk('faqCategorySliceData/deleteFaqCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/faq-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory deleted successfully.');
        await dispatch(getFaqCategory());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFaqCategory = createAsyncThunk('faqCategorySliceData/createFaqCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/faq-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory added succesfully.');
        await dispatch(getFaqCategory());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFaqCategory = createAsyncThunk('faqCategorySliceData/updateFaqCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/faq-category/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'FaqCategory updated successfully.');
        await dispatch(getFaqCategory());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFaqCategoryStatus = createAsyncThunk('faqCategorySliceData/updateFaqCategoryStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/faq-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getFaqCategory());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const faqCategorySlice = createSlice({
    name: 'faqCategorySliceData',
    initialState: {
        faqCategory: [],
        faqCategoryStatus: []
    },
    extraReducers: (builder) => {
        builder.addCase(getFaqCategory.fulfilled, (state, action) => {
            state.faqCategory = action?.payload;
            return;
        });
        builder.addCase(getFaqCategoryStatus.fulfilled, (state, action) => {
            state.faqCategoryStatus = action?.payload;
            return;
        });
    }
});

export default faqCategorySlice.reducer;
