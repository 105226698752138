import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleSpecification = createAsyncThunk('vehicleSpecificationSlice/getVehicleSpecification', async () => {
    try {
        let response = await axios.get('/vehicle-specification', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleSpecificationOption = createAsyncThunk('vehicleSpecificationSlice/getVehicleSpecificationOption', async () => {
    try {
        // showLoader(true);
        let response = await axios.get('/vehicle-specification?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleSpecificationOptionWithQuery = createAsyncThunk(
    'vehicleSpecificationSlice/getVehicleSpecificationOptionWithQuery',
    async (data) => {
        try {
            const query = queryString.stringify(data);

            let response = await axios.get('/vehicle-specification?option=true' + `&${query}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const createVehicleSpecification = createAsyncThunk(
    'vehicleSpecificationSlice/createVehicleSpecification',
    async (data, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.post('/vehicle-specification', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Vehicle created successfully.');
            dispatch(getVehicleSpecification());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateVehicleSpecification = createAsyncThunk(
    'vehicleSpecificationSlice/updateVehicleSpecification',
    async (data, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/vehicle-specification/' + data?.id, data?.data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Vehicle updated successfully.');
            dispatch(getVehicleSpecification());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const deleteVehicleSpecification = createAsyncThunk(
    'vehicleSpecificationSlice/deleteVehicleSpecification',
    async (deleteId, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.delete('/vehicle-specification/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Vehicle deleted successfully');
            dispatch(getVehicleSpecification());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateVehicleSpecificationStatus = createAsyncThunk(
    'vehicleSpecificationSlice/updateVehicleSpecificationStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/vehicle-specification/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getVehicleSpecification());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.err(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const vehicleSpecificationSlice = createSlice({
    name: 'vehicleSpecificationSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleSpecification.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleSpecificationOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleSpecificationSlice.reducer;
