import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCurrentPatch = createAsyncThunk('liveTrackingSlice/getCurrentPatch', async () => {
    try {
        showLoader(true);

        let response = await axios.get('/current-patch', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const liveTrackingSlice = createSlice({
    name: 'liveTrackingSlice',
    initialState: {
        data: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getCurrentPatch.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default liveTrackingSlice.reducer;
