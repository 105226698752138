import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleCategory = createAsyncThunk('vehicleCategorySlice/getVehicleCategory', async () => {
    try {
        let response = await axios.get('/vehicle-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleCategoryOption = createAsyncThunk('vehicleCategorySlice/getVehicleCategoryOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getVehicleCategoryOptionWithQuery = createAsyncThunk(
    'vehicleCategorySlice/getVehicleCategoryOptionWithQuery',
    async (data) => {
        try {
            const query = queryString.stringify(data);

            showLoader(true);
            let response = await axios.get('/vehicle-category?option=true' + `&${query}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const createVehicleCategory = createAsyncThunk('vehicleCategorySlice/createVehicleCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle Category created successfully.');
        dispatch(getVehicleCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleCategory = createAsyncThunk('vehicleCategorySlice/updateVehicleCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-category/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle category updated successfully.');
        dispatch(getVehicleCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicleCategory = createAsyncThunk('vehicleCategorySlice/deleteVehicleCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Category deleted successfully');
        dispatch(getVehicleCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleCategoryStatus = createAsyncThunk(
    'vehicleCategorySlice/updateVehicleCategoryStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/vehicle-category/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getVehicleCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.err(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const vehicleCategorySlice = createSlice({
    name: 'vehicleCategorySlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleCategory.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleCategoryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleCategorySlice.reducer;
