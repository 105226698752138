import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getAutoInsurance = createAsyncThunk('autoInsuranceSlice/getAutoInsurance', async () => {
    try {
        let response = await axios.get('/client/app-user-policy', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAutoInsuranceByVehicle = createAsyncThunk('autoInsuranceSlice/getAutoInsuranceByVehicle', async (id) => {
    try {
        let response = await axios.get('/client/app-user-policy/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getHistoryAutoInsuranceByVehicle = createAsyncThunk('autoInsuranceSlice/getHistoryAutoInsuranceByVehicle', async (id) => {
    try {
        let response = await axios.get('/client/history-app-user-policy/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAutoInsuranceOption = createAsyncThunk('autoInsuranceSlice/getAutoInsuranceOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/client/app-user-policy?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAutoInsurance = createAsyncThunk('autoInsuranceSlice/createAutoInsurance', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/app-user-policy', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Insurence created successfully.');
        dispatch(getAutoInsurance());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAutoInsurance = createAsyncThunk('autoInsuranceSlice/updateAutoInsurance', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/app-user-policy/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Insurance updated successfully.');
        dispatch(getAutoInsuranceByVehicle(data?.vehicleId));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteAutoInsurance = createAsyncThunk('autoInsuranceSlice/deleteAutoInsurance', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/app-user-policy/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Insurance deleted successfully');
        dispatch(getAutoInsurance());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAutoInsuranceStatus = createAsyncThunk('autoInsuranceSlice/updateAutoInsuranceStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/app-user-policy/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getAutoInsurance());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const autoInsuranceSlice = createSlice({
    name: 'autoInsuranceSlice',
    initialState: {
        data: [],
        selectedData: [],
        option: [],
        historyData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAutoInsurance.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getAutoInsuranceByVehicle.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
        builder.addCase(getHistoryAutoInsuranceByVehicle.fulfilled, (state, action) => {
            state.historyData = action.payload;
            return;
        });
        builder.addCase(getAutoInsuranceOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default autoInsuranceSlice.reducer;
