import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getDoctor = createAsyncThunk('doctorSlice/getDoctor', async () => {
    try {
        let response = await axios.get('/doctor', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSelectedDoctorReport = createAsyncThunk('doctorSlice/getSelectedDoctorReport', async (id) => {
    try {
        let response = await axios.get('/doctor/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const doctorSlice = createSlice({
    name: 'doctorSlice',
    initialState: {
        data: [],
        selectedDoctorReport: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDoctor.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getSelectedDoctorReport.fulfilled, (state, action) => {
            state.selectedDoctorReport = action.payload;
            return;
        });
    }
});

export default doctorSlice.reducer;
