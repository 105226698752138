import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSOS = createAsyncThunk('sosSlice/getSOS', async () => {
    try {
        let response = await axios.get('/sos', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSOSOption = createAsyncThunk('sosSlice/getSOSOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/sos?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSOS = createAsyncThunk('sosSlice/createSOS', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/sos', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'SOS created successfully.');
        dispatch(getSOS());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSOS = createAsyncThunk('sosSlice/updateSOS', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/sos/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'SOS updated successfully.');
        dispatch(getSOS());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSOS = createAsyncThunk('sosSlice/deleteSOS', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/sos/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'SOS deleted successfully');
        dispatch(getSOS());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSOSStatus = createAsyncThunk('sosSlice/updateSOSStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/sos/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSOS());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const sosSlice = createSlice({
    name: 'sosSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSOS.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getSOSOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default sosSlice.reducer;
