import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleKycDoc = createAsyncThunk('vehicleKycDocSlice/getVehicleKycDoc', async () => {
    try {
        let response = await axios.get('/vehicle-kyc-doc', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleKycDocOption = createAsyncThunk('vehicleKycDocSlice/getVehicleKycDocOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-kyc-doc?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVehicleKycDoc = createAsyncThunk('vehicleKycDocSlice/createVehicleKycDoc', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle-kyc-doc', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle kyc doc created successfully.');
        dispatch(getVehicleKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleKycDoc = createAsyncThunk('vehicleKycDocSlice/updateVehicleKycDoc', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-kyc-doc/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle kyc doc updated successfully.');
        dispatch(getVehicleKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicleKycDoc = createAsyncThunk('vehicleKycDocSlice/deleteVehicleKycDoc', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle-kyc-doc/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle kyc doc deleted successfully');
        dispatch(getVehicleKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleKycDocStatus = createAsyncThunk('vehicleKycDocSlice/updateVehicleKycDocStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-kyc-doc/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getVehicleKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vehicleKycDocSlice = createSlice({
    name: 'vehicleKycDocSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleKycDoc.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleKycDocOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleKycDocSlice.reducer;
