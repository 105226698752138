import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getInsuranceOffer = createAsyncThunk('insuranceOfferSlice/getInsuranceOffer', async () => {
    try {
        let response = await axios.get('/insurance-offer', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getInsuranceOfferOption = createAsyncThunk('insuranceOfferSlice/getInsuranceOfferOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/insurance-offer?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createInsuranceOffer = createAsyncThunk('insuranceOfferSlice/createInsuranceOffer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/insurance-offer', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type created successfully.');
        dispatch(getInsuranceOffer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateInsuranceOffer = createAsyncThunk('insuranceOfferSlice/updateInsuranceOffer', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/insurance-offer/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type updated successfully.');
        dispatch(getInsuranceOffer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteInsuranceOffer = createAsyncThunk('insuranceOfferSlice/deleteInsuranceOffer', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/insurance-offer/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type deleted successfully');
        dispatch(getInsuranceOffer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateInsuranceOfferStatus = createAsyncThunk('insuranceOfferSlice/updateInsuranceOfferStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/insurance-offer/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getInsuranceOffer());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        // toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const insuranceOfferSlice = createSlice({
    name: 'insuranceOfferSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getInsuranceOffer.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getInsuranceOfferOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default insuranceOfferSlice.reducer;
