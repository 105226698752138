import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getMapSetting = createAsyncThunk('mapSettingSlice/getMapSetting', async () => {
    try {
        let response = await axios.get('/map-setting', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMapSetting = createAsyncThunk('mapSettingSlice/createMapSetting', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/map-setting', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Map Setting created successfully.');
        dispatch(getMapSetting());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMapSetting = createAsyncThunk('mapSettingSlice/updateMapSetting', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/map-setting/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Map setting updated successfully.');
        dispatch(getMapSetting());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMapSettingStatus = createAsyncThunk('mapSettingSlice/updateMapSettingStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/map-setting/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        // dispatch(getMapSetting());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const mapSettingSlice = createSlice({
    name: 'mapSettingSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMapSetting.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default mapSettingSlice.reducer;
