import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDriver = createAsyncThunk('driverSlice/getDriver', async () => {
    try {
        let response = await axios.get('/drivers', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getRemainingDriver = createAsyncThunk('driverSlice/getRemainingDriver', async () => {
    try {
        let response = await axios.get('/client/vehicle-invitation?type=2&status=0', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDriver = createAsyncThunk('driverSlice/createDriver', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/vehicle-invitation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Driver created successfully.');
        dispatch(getDriver());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createDriverInvitation = createAsyncThunk('driverSlice/createDriverInvitation', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/find-driver', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Driver fi successfully.');
        dispatch(getDriver());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDriver = createAsyncThunk('driverSlice/deleteDriver', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/vehicle-invitation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Driver deleted successfully');
        dispatch(getDriver());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const onBoardingDriver = createAsyncThunk('driverSlice/onBoardingDriver', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/client/driver-on-boarding', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Driver created successfully.');
        dispatch(getDriver());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const driverSlice = createSlice({
    name: 'driverSlice',
    initialState: {
        data: [],
        remainingDriver: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDriver.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getRemainingDriver.fulfilled, (state, action) => {
            state.remainingDriver = action.payload;
            return;
        });
    }
});

export default driverSlice.reducer;
