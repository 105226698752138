import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDeviceCategory = createAsyncThunk('deviceCategorySlice/getDeviceCategory', async () => {
    try {
        let response = await axios.get('/device-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDeviceCategoryOption = createAsyncThunk('deviceCategorySlice/getDeviceCategoryOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/device-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDeviceCategory = createAsyncThunk('deviceCategorySlice/createDeviceCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/device-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'RoadType created successfully.');
        dispatch(getDeviceCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDeviceCategory = createAsyncThunk('deviceCategorySlice/updateDeviceCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/device-category/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'RoadType updated successfully.');
        dispatch(getDeviceCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteDeviceCategory = createAsyncThunk('deviceCategorySlice/deleteDeviceCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/device-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'RoadType deleted successfully');
        dispatch(getDeviceCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateDeviceCategoryStatus = createAsyncThunk('deviceCategorySlice/updateDeviceCategoryStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/device-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getDeviceCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deviceCategorySlice = createSlice({
    name: 'deviceCategorySlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDeviceCategory.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getDeviceCategoryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default deviceCategorySlice.reducer;
