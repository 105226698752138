import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSpeedConfiguration = createAsyncThunk('speedConfigurationSlice/getSpeedConfiguration', async () => {
    try {
        let response = await axios.get('/speed-configuration', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSpeedConfigurationOption = createAsyncThunk('speedConfigurationSlice/getSpeedConfigurationOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/speed-configuration?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSpeedConfiguration = createAsyncThunk('speedConfigurationSlice/createSpeedConfiguration', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/speed-configuration', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Speed Configuration created successfully.');
        dispatch(getSpeedConfiguration());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSpeedConfiguration = createAsyncThunk('speedConfigurationSlice/updateSpeedConfiguration', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/speed-configuration/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Speed Configuration updated successfully.');
        dispatch(getSpeedConfiguration());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSpeedConfiguration = createAsyncThunk(
    'speedConfigurationSlice/deleteSpeedConfiguration',
    async (deleteId, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.delete('/speed-configuration/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Speed Configuration deleted successfully');
            dispatch(getSpeedConfiguration());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateSpeedConfigurationStatus = createAsyncThunk(
    'speedConfigurationSlice/updateSpeedConfigurationStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/speed-configuration/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getSpeedConfiguration());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.err(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const speedConfigurationSlice = createSlice({
    name: 'speedConfigurationSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSpeedConfiguration.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getSpeedConfigurationOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default speedConfigurationSlice.reducer;
