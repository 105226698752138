import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getRto = createAsyncThunk('rtoSlice/getRto', async () => {
    try {
        let response = await axios.get('/rto', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRtoOption = createAsyncThunk('rtoSlice/getRtoOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/rto?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRto = createAsyncThunk('rtoSlice/createRto', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/rto', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc created successfully.');
        dispatch(getRto());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRto = createAsyncThunk('rtoSlice/updateRto', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/rto/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc updated successfully.');
        dispatch(getRto());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteRto = createAsyncThunk('rtoSlice/deleteRto', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/rto/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc deleted successfully');
        dispatch(getRto());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRtoStatus = createAsyncThunk('rtoSlice/updateRtoStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/rto/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getRto());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const rtoSlice = createSlice({
    name: 'rtoSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRto.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getRtoOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default rtoSlice.reducer;
