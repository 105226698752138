import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
// import themeConfig from '../../../../configs/themeConfig';
import themeConfig from '../../../configs/themeConfig';
import queryString from 'query-string';
const initialTakeOwnerShipfun = () => {
    const item = window.localStorage.getItem('takeOwnerShip');

    return item ? JSON.parse(item) : themeConfig.layout.takeOwnerShip;
};

export const getVehicleOptionWithFilter = createAsyncThunk('vehicleSlice/getVehicleOptionWithFilter', async (data) => {
    try {
        // showLoader(true);
        const query = queryString.stringify(data);

        let response = await axios.get('/vehicle/with-filters?option=true&skip=0&take=5' + `&${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data.data.rows);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        data: [],
        options: [],
        selectedRole: {},
        module: [],
        initialTakeOwnerShip: initialTakeOwnerShipfun(),
        optionWithFilter: []
    },
    reducers: {
        handleTackOwherShip: (state, action) => {
            state.initialTakeOwnerShip = action.payload;
            localStorage.setItem('takeOwnerShip', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getVehicleOptionWithFilter.fulfilled, (state, action) => {
            state.optionWithFilter = action.payload;
            return;
        });
    }
});

export const { handleTackOwherShip } = dashboardSlice.actions;

export default dashboardSlice.reducer;
