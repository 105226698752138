import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getChallanRule = createAsyncThunk('challanRuleSlice/getChallanRule', async () => {
    try {
        let response = await axios.get('/challan-rule', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getChallanRuleOption = createAsyncThunk('challanRuleSlice/getChallanRuleOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/challan-rule?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createChallanRule = createAsyncThunk('challanRuleSlice/createChallanRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/challan-rule', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Challan rule created successfully.');
        dispatch(getChallanRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateChallanRule = createAsyncThunk('challanRuleSlice/updateChallanRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/challan-rule/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Challan rule updated successfully.');
        dispatch(getChallanRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteChallanRule = createAsyncThunk('challanRuleSlice/deleteChallanRule', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/challan-rule/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Challan rule deleted successfully');
        dispatch(getChallanRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateChallanRuleStatus = createAsyncThunk('challanRuleSlice/updateChallanRuleStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/challan-rule/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getChallanRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const challanRuleSlice = createSlice({
    name: 'challanRuleSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getChallanRule.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getChallanRuleOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default challanRuleSlice.reducer;
