import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import themeConfig from '../../../configs/themeConfig';

import queryString from 'query-string';

export const getAppUser = createAsyncThunk('appUserSlice/getAppUser', async () => {
    try {
        showLoader(true);

        let response = await axios.get('/app-user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getOnlyAppUser = createAsyncThunk('appUserSlice/getOnlyAppUser', async () => {
    try {
        showLoader(true);

        let response = await axios.get('/app-user?profileType=0', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAppUserProfile = createAsyncThunk('appUserSlice/getAppUserProfile', async () => {
    try {
        showLoader(true);

        let response = await axios.get('/app-user/profile', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAppUserById = createAsyncThunk('appUserSlice/getUserById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/app-user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAppUser = createAsyncThunk('appUserSlice/createAppUser', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/app-user', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'App User created successfully.');
        dispatch(getAppUser());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAppUser = createAsyncThunk('appUserSlice/updateAppUser', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/app-user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'App User updated successfully.');
        dispatch(getAppUser());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteAppUser = createAsyncThunk('appUserSlice/deleteAppUser', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/app-user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'AppUser deleted successfully');
        dispatch(getAppUser());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
///** User Password Reset Api
export const updateAppUserPassword = createAsyncThunk('appUserSlice/updateUserPassword', async (data) => {
    try {
        let response = await axios.put('/admin/user-reset-password/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User Password reset successfully!');
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getWalletTransactionHistory = createAsyncThunk('appUserSlice/getWalletTransactionHistory', async (data) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/wallet-transaction?status=1' + `&${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getWalletRechargeHistory = createAsyncThunk('appUserSlice/getWalletRechargeHistory', async (query) => {
    // let myStr = query;
    // // replacing for % symbol
    // myStr = String(myStr).replaceAll('%', '$*$');

    try {
        let response = await axios.get('/client/wallet-transaction?transactionType=1&status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

const trasactionPageActiveTab = () => {
    const item = window.localStorage.getItem('trasactionPageActiveTab');

    return item ? JSON.parse(item) : themeConfig.layout.trasactionPageActiveTab;
};

export const appUserSlice = createSlice({
    name: 'appUserSlice',
    initialState: {
        data: [],
        appUser: [],
        options: [],
        selectedAppUser: {},
        profile: [],
        trasactionHistory: [],
        rechargeHistory: [],
        trasactionPageActiveTab: trasactionPageActiveTab()
    },
    reducers: {
        handleSelectedAppUser: (state, action) => {
            state.selectedAppUser = action.payload;
        },
        removeSelectedAppUser: (state, action) => {
            state.selectedAppUser = {};
        },
        handleTrasactionTab: (state, action) => {
            state.trasactionPageActiveTab = action.payload;
            localStorage.setItem('trasactionPageActiveTab', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAppUser.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getOnlyAppUser.fulfilled, (state, action) => {
            state.appUser = action.payload;
            return;
        });
        builder.addCase(getAppUserProfile.fulfilled, (state, action) => {
            state.profile = action.payload;
            return;
        });
        builder.addCase(getAppUserById.fulfilled, (state, action) => {
            state.selectedAppUser = action.payload;
            return;
        });
        builder.addCase(getWalletTransactionHistory.fulfilled, (state, action) => {
            state.trasactionHistory = action.payload;
            return;
        });
        builder.addCase(getWalletRechargeHistory.fulfilled, (state, action) => {
            state.rechargeHistory = action.payload;
            return;
        });
    }
});

export const { handleSelectedAppUser, removeSelectedAppUser, handleTrasactionTab } = appUserSlice.actions;

export default appUserSlice.reducer;
