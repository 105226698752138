import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getRoadSpeedRule = createAsyncThunk('roadSpeedRuleSlice/getRoadSpeedRule', async (id) => {
    let query = '';

    if (id) {
        query = '?roadId=' + id;
    }

    try {
        let response = await axios.get('/road-speed-rule' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoadSpeedRuleOption = createAsyncThunk('roadSpeedRuleSlice/getRoadSpeedRuleOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/road-speed-rule?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRoadSpeedRule = createAsyncThunk('roadSpeedRuleSlice/createRoadSpeedRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/road-speed-rule', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule created successfully.');
        dispatch(getRoadSpeedRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRoadSpeedRule = createAsyncThunk('roadSpeedRuleSlice/updateRoadSpeedRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/road-speed-rule/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule updated successfully.');
        dispatch(getRoadSpeedRule(data?.roadId));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteRoadSpeedRule = createAsyncThunk('roadSpeedRuleSlice/deleteRoadSpeedRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/road-speed-rule/' + data.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Road speed rule deleted successfully');
        dispatch(getRoadSpeedRule(data?.roadId));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRoadSpeedRuleStatus = createAsyncThunk('roadSpeedRuleSlice/updateRoadSpeedRuleStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/road-speed-rule/status/' + data?.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getRoadSpeedRule(data?.roadId));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const roadSpeedRuleSlice = createSlice({
    name: 'roadSpeedRuleSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRoadSpeedRule.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getRoadSpeedRuleOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default roadSpeedRuleSlice.reducer;
