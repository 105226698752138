import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleBrand = createAsyncThunk('vehicleBrandSlice/getVehicleBrand', async () => {
    try {
        let response = await axios.get('/vehicle-brand', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleBrandOption = createAsyncThunk('vehicleBrandSlice/getVehicleBrandOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-brand?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVehicleBrand = createAsyncThunk('vehicleBrandSlice/createVehicleBrand', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle-brand', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle brand created successfully.');
        dispatch(getVehicleBrand());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleBrand = createAsyncThunk('vehicleBrandSlice/updateVehicleBrand', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-brand/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle brand updated successfully.');
        dispatch(getVehicleBrand());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicleBrand = createAsyncThunk('vehicleBrandSlice/deleteVehicleBrand', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle-brand/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle brand deleted successfully');
        dispatch(getVehicleBrand());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleBrandStatus = createAsyncThunk('vehicleBrandSlice/updateVehicleBrandStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-brand/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getVehicleBrand());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vehicleBrandSlice = createSlice({
    name: 'vehicleBrandSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleBrand.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleBrandOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleBrandSlice.reducer;
